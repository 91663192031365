'use client';

import { useTranslations } from 'next-intl';
import { Header } from '@/components/layout/header';
import { PageWrapper } from '@/components/layout/page/PageWrapper';
import { Button } from '@/components/ui/button/Button';
import { PATHS } from '@/constants/path';
import { useRouter } from '@/i18n/routing';

export default function NotFound() {
  const { push } = useRouter();
  const t = useTranslations();

  return (
    <PageWrapper bgColor='white' className='flex flex-col items-center'>
      <Header logo={true} desktopHeaderHidden={true} />
      <div className='whitespace-pre pb-[8.5rem] pt-[10rem] text-center'>
        {t('common_error_generic_description_three')}
      </div>
      <div className='w-full px-2.5x'>
        <Button label={t('common-back_to_top')} fullWidth onClick={() => push(PATHS.ROOT)} />
      </div>
    </PageWrapper>
  );
}
